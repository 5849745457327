import { Breadcrumb, Button, Row, Select, Col, Table, Pagination, Flex, notification, Modal } from "antd";

import { apis, keys, paths } from "../../../../constants";
import style from "./danhSachChiThiXuatKho.module.less";
import { DownloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { formatWeightText, localGet } from "../../../../helpers";
import AxiosService from "../../../../common/AxiosService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import configs from "../../../../constants/configs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ExportDetailModal from "./ExportDetailModal/ExportDetailModal";
import { appLoading } from "../../../../ducks/slices/appSlice";

const DanhSachChiThiXuatKho = () => {


  const [data, set_data] = useState([]);
  const [total, set_total] = useState(0);
  const [currentDataRequest, set_currentDataRequest] = useState({})
  const [currentPage, set_currentPage] = useState(1);
  const [modal, contextHolder] = Modal.useModal();
  const [filterStatus, set_filterStatus] = useState(null)

  const history = useHistory();
  const detailModal = useRef();
  const [loading, set_loading] = useState(true)

  const [pageSize, set_pageSize] = useState(10);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth)

  useEffect(() => {
    initData()
  }, [])

  const initData = () => {
    let dataRequest = {
      start: 0,
      length: pageSize,
      status: filterStatus,
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const requestGetData = (dataRequest) => {
    set_loading(true)
    AxiosService.post(apis.Url_Api_Post_LayDSChiThiXuatKho, dataRequest)
      .then(response => {
        let { data } = response;
        if (!!data && data.message === "OK") {
          if (dataRequest.start === 0) {
            set_total(data.totalRecords)
          }
          set_data(data.data)
        } else if (data.message === keys.api_message_EMPTY) {
          set_total(0)
          set_data([])
          notification.error({ message: "Không có dữ liệu" })
        }
        set_loading(false)
      })
      .catch(err => {
        console.log(err);
        set_loading(false)
        notification.error({ message: "Lỗi hệ thống" })
      })
  }

  const onChangePageSize = (val) => {
    set_pageSize(val);
    set_currentPage(1);
    let dataRequest = {
      ...currentDataRequest,
      start: 0,
      length: val,
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const onChangePage = (val) => {
    set_currentPage(val)
    let dataRequest = {
      ...currentDataRequest,
      start: pageSize * (val - 1),
      length: pageSize * val,
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const reloadData = () => {
    requestGetData(currentDataRequest)
  }

  const onClickRow = (record, index) => {
    detailModal.current.openModal(record.id, record.status)
  }

  const handleChangeFilterStatus = (e) => {
    set_filterStatus(e)

    let dataRequest = {
      start: 0,
      length: pageSize,
      status: e
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
    set_currentPage(1)
  }

  const onPressToCreateTicket = () => {
    history.push(paths.main_them_moi_xuat_kho)
  }

  const columns = [
    {
      title: 'Ngày Tạo',
      dataIndex: 'createDate',
      key: 'createDate',
      width: '200px',
      render: (text) => moment(text).format("DD/MM/YYYY"),
    },
    {
      title: 'Ngày Xuất',
      dataIndex: 'deliverDate',
      key: 'deliverDate',
      width: '200px',
      render: (text) => <div>{text}</div>
    },
    {
      title: 'Số chỉ thị',
      dataIndex: 'orderNo',
      key: 'orderNo',
      width: '200px',
      render: (value) => <div className={style["important-text"]}>{value}</div>
    },

    {
      title: 'Số Lượng',
      dataIndex: 'totalRoll',
      key: 'totalRoll',
      width: '150px',
      render: (totalRoll, item) => totalRoll
    },
    {
      title: <div><div>Tổng Trọng Lượng</div><div style={{ paddingLeft: '35px' }}>(Kg)</div></div>,
      dataIndex: 'totalNetWeight',
      key: 'totalNetWeight',
      width: '250px',
      render: (totalNetWeight) => formatWeightText(totalNetWeight)
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Thao tác',
      dataIndex: 'value',
      width: '200px',
      key: 'value',
      render: (val, item) => <Flex justify="flex-end">

        {(item.status === '2') && <Button type='primary'
          onClick={(e) => { e.stopPropagation(); confirmExportRequest(item) }}
        >
          Phê duyệt
        </Button>}
        {(item.status === '2' || item.status === '3') && <Button key="submit"
          type="danger"
          style={{ marginLeft: '10px' }}
          onClick={(e) => { e.stopPropagation(); onRequestDelele(item) }}
        >
          Hủy
        </Button>}
        {(item.status !== '-1') && <Button type='primary' ghost onClick={(e) => { e.stopPropagation(); doExportData(item) }} style={{ marginLeft: '10px' }}>Tải xuống</Button>}
      </Flex>
    },
    {
      title: 'Trạng Thái',
      dataIndex: 'status',
      key: 'status',
      width: '150px',
      render: (value) => getStatusLabel(value)
    },

  ];

  const getStatusLabel = (status) => {

    switch (status) {
      case '2':
        return "Chờ Duyệt";
      case '1':
        return "Hoàn Thành"
      case '3':
        return "Đã Duyệt/Chờ Xuất";
      case '-1':
        return "Hủy";
      default:
        return "Chờ Duyệt";
    }
  }

  const confirmExportRequest = (item) => {
    requestApiConfirmExport([item.id])
  }


  const requestApiConfirmExport = (listId) => {
    dispatch(appLoading(true))
    let dataRequest = {
      "listIdDeliver": listId
    }
    AxiosService.post(apis.Url_Api_Post_XacNhanChiThiXuatKho, dataRequest)
      .then(res => {
        let { data } = res;
        if (!!data && data.message === "OK") {
          notification.success({ message: "Xác nhận thành công" })
          requestGetNotiToken()
        } else {
          notification.error({ message: data.message })
        }

        reloadData()
        dispatch(appLoading(false))
      })
      .catch(e => {
        notification.error({ message: e.message });
        dispatch(appLoading(false))
      })
  }

  const requestGetNotiToken = () => {
    let dataRequest = {
      "lstRole": [keys.userRoles.KhoTonCuon_Request]
    }
    AxiosService.post(apis.Url_Get_ListMessageTokenByRole, dataRequest)
      .then(response => {
        let { data } = response;
        if (!!data && data.message === "OK") {
          requestSendNoti(data.data[keys.userRoles.KhoTonCuon_Request])
        } else {
        }
      })
      .catch(err => console.log(err))
  }

  const requestSendNoti = (listToken = []) => {
    let dataRequest = {
      "subject": "NSMP STORAGE",
      "content": "Có yêu cầu xuất kho mới",
      "data": {
        "message": "xuất kho mới"
      },
      "registrationToken": listToken.map(item => item.token)
    }
    console.log('send noti', dataRequest);
    AxiosService.post(apis.Url_Get_SendNotification, dataRequest)
      .then(response => {
        let { data } = response;
        console.log('send noti response', data);
      })
      .catch(err => console.log(err))
  }


  const doExportData = async (data) => {
    dispatch(appLoading(true))
    fetch(apis.Url_Api_Post_ExportDanhSachChiThiXuatKho, {
      method: 'POST',
      body: JSON.stringify({
        "id": data.id
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: await localGet(keys.access_token),
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = "CT_XuatKho_" + data.orderNo + ".xlsx";

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
        dispatch(appLoading(false))
      }).catch(error => {
        dispatch(appLoading(false))
        notification.error({ message: "Không có dữ liệu" })
      });
  }

  const onRequestDelele = (item) => {
    modal.confirm({
      title: 'Xác nhận hủy',
      icon: <ExclamationCircleOutlined />,
      content: 'Bạn chắc chắn muốn hủy chỉ thị này?',
      okText: 'Hủy',
      okType: 'danger',
      onOk: () => onsendRequestCancel(item),
      cancelText: 'Đóng',
    });

  }

  const onsendRequestCancel = (item) => {
    dispatch(appLoading(true))
    let dataRequest = {
      "listCancelId": [item.id],
      "reasonCancel": "test hủy chị thị kho"
    }
    AxiosService.post(apis.Url_Api_Post_HuyChiThiXuatKho, dataRequest)
      .then(res => {
        let { data } = res;
        console.log(res);
        if (!!data && data.message === "OK") {
          notification.success({ message: "Hủy thành công" })
        } else {
          notification.error({ message: data.message })
        }
        dispatch(appLoading(false))
        reloadData()
      })
      .catch(e => {
        notification.error({ message: e.message });
        dispatch(appLoading(true))
      })
  }



  return (
    <div className={style["main-container"]}>
      <Breadcrumb items={[{
        title: 'Xuất kho',
      },
      {
        title: 'Chỉ thị xuất',
      }]} />

      <div className={style['main']}>
        <Row gutter={[10, 20]} align={'flex-start'}>
          <Col span={3} className={style['filter-title']}>Trạng thái</Col>
          <Col span={5}><Select
            className={style['filter-select']}
            onChange={handleChangeFilterStatus}
            defaultValue={filterStatus}
            value={filterStatus}
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={[
              { value: null, label: 'Tất cả' },
              { value: '3', label: 'Chờ xuất' },
              { value: '2', label: 'Chờ phê duyệt' },
              { value: '1', label: 'Hoàn thành' },
              { value: '-1', label: 'Hủy' },
            ]}
          /></Col>

        </Row>

        <Row gutter={60} align={'middle'} justify={'center'} style={{ marginTop: '20px' }}>
          {/* <Col span={4}><Button icon={<DownloadOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tải xuống</Button></Col> */}
          <Col span={4}><Button onClick={onPressToCreateTicket} className={style['filter-button']}>Tạo chỉ thị mới</Button></Col>
        </Row>

        <Table loading = {loading} columns={columns} dataSource={data} className={style['table']} pagination={{ position: ['none', 'none'], pageSize: pageSize }} rowClassName={style['table-row']} onRow={(record, rowIndex) => {
          return {
            onClick: (event) => { onClickRow(record, rowIndex) }, // click row
          };
        }} rowKey={(record) => record.rollId + record.orderNo} />
        <Row justify={'end'} className={style['pagination']}>
          <Pagination
            locale={{ items_per_page: '' }}
            pageSizeOptions={[5, 10, 20]}
            className={style["pagination-bar"]}
            pageSize={pageSize}
            hideOnSinglePage
            current={currentPage}
            onChange={(index) => onChangePage(index)}
            defaultCurrent={1}
            total={total}
            showSizeChanger={false}
          />
          <Select value={pageSize} defaultValue={pageSize} options={configs.PageSizes} onChange={onChangePageSize} style={{ marginLeft: '10px' }} pagination={{ position: ['none', 'none'], pageSize: pageSize }} />
        </Row>
        <ExportDetailModal ref={detailModal} reloadData={reloadData} />
        {contextHolder}
      </div>
    </div>
  );


};



export default DanhSachChiThiXuatKho;