import React, { forwardRef, useImperativeHandle, useState } from "react";
import style from "./xuatBaoCao.module.less";
import moment from "moment";
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  notification,
} from "antd";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/vi_VN";
import "dayjs/locale/vi";
import { apis, keys } from "../../../../constants";
import { localGet } from "../../../../helpers";
import { useDispatch } from "react-redux";
import { appLoading } from "../../../../ducks/slices/appSlice";

const rangePresets = [
  {
    label: "7 ngày trước",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "14 ngày trước",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "30 ngày trước",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "90 ngày trước",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
  {
    label: "1 năm trước",
    value: [dayjs().add(-365, "d"), dayjs()],
  },
];

const XuatBaoCao = forwardRef((props, ref) => {
  const [allData_filterStatus, set_allData_filterStatus] = useState(
    statusOptions[0].value
  );
  const [allData_filterStorage, set_allData_filterStorage] = useState(
    storageOptions[0].value
  );
  const [allData_filterStartTime, set_allData_filterStartTime] = useState("");
  const [allData_filterEndtime, set_allData_filterEndtime] = useState("");

  const [monthlyData_filterStartTime, set_monthlyData_filterStartTime] =
    useState("");
  const [monthlyData_filterEndtime, set_monthlyData_filterEndtime] =
    useState("");
  const [importData_filterTime, set_importData_filterTime] = useState(dayjs());

  const dispatch = useDispatch();

  const handleChangeAllDataStatus = (data) => {};
  const handleChangeAllDataStorage = (data) => {};
  const handleChangeMonthlyDataStatus = (data) => {};
  const handleChangeMonthlyDataStorage = (data) => {};

  const onRangeChangeAllData = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      set_allData_filterStartTime(dates[0]);
      set_allData_filterEndtime(dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };

  const onRangeChangeMonthlyData = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      set_monthlyData_filterStartTime(dates[0]);
      set_monthlyData_filterEndtime(dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };

  const doExportAllData = async () => {
    dispatch(appLoading(true));
    const formatStartDate = dayjs(allData_filterStartTime).format("DD/MM/YYYY");
    const formatEndDate = dayjs(allData_filterEndtime).format("DD/MM/YYYY");
    
    fetch(apis.Url_Api_Post_BaoCao_ExportBCThucTe, {
      method: "POST",
      body: JSON.stringify({
        dateFrom: formatStartDate,
        dateTo: formatEndDate,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: await localGet(keys.access_token),
      },
    })
      .then((response) => {
        if (response.status === 500) {
          dispatch(appLoading(false));
          notification.error({ message: "Lỗi hệ thống!" });
          return null;
        } else if (response.status === 200) {
          return response.blob();
        } else {
          dispatch(appLoading(false));
          notification.error({ message: "Không có dữ liệu!" });
          return null;
        }
      })
      .then((blob) => {
        if (!blob) return;
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download =
          "BaoCao_TheoKy_" +
          dayjs(allData_filterStartTime).format("DD/MM/YYYY") +
          "-" +
          dayjs(allData_filterEndtime).format("DD/MM/YYYY") +
          ".xlsx";

        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link);
        dispatch(appLoading(false));
      });
  };

  const doExportImportDataByDate = async () => {
    try {
      dispatch(appLoading(true));
      fetch(apis.Url_Api_Post_BaoCao_ExportTheoNgay, {
        method: "POST",
        body: JSON.stringify({
          importDate: dayjs(importData_filterTime).format("DD/MM/YYYY"),
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: await localGet(keys.access_token),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));

          const link = document.createElement("a");
          link.href = url;
          link.download =
            "BaoCao_" +
            dayjs(importData_filterTime).format("DD/MM/YYYY") +
            ".xlsx";

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
          dispatch(appLoading(false));
        })
        .catch((error) => {
          dispatch(appLoading(false));
          console.log(error);
          notification.error({ message: "Không có dữ liệu" });
        });
    } catch (error) {
      dispatch(appLoading(false));
      console.log(error);
      notification.error({ message: "Không có dữ liệu" });
    }
  };

  return (
    <div className={style["main-container"]}>
      <Breadcrumb
        items={[
          {
            title: "Báo cáo",
          },
          {
            title: "Trích xuất dữ liệu",
          },
        ]}
      />
      <div className={style["main"]}>
        <div className={style["filter-row-title"]}>Báo cáo thực tế</div>
        <Row align={"middle"} gutter={10} className={style["filter-row"]}>
          <Col span={2} className={style["filter-title"]}>
            Phân loại dữ liệu
          </Col>
          <Col span={4}>
            <Select
              className={style["filter-select"]}
              onChange={handleChangeAllDataStatus}
              value={allData_filterStatus}
              dropdownStyle={{
                borderWidth: 1,
                borderColor: " rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
              }}
              options={statusOptions}
              disabled
            />
          </Col>
          <Col span={2} className={style["filter-title"]}>
            Nhà kho
          </Col>
          <Col span={4}>
            <Select
              className={style["filter-select"]}
              onChange={handleChangeAllDataStorage}
              value={allData_filterStorage}
              dropdownStyle={{
                borderWidth: 1,
                borderColor: " rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
              }}
              options={storageOptions}
              disabled
            />
          </Col>
          <Col span={2} className={style["filter-title"]}>
            {" "}
            Thời gian{" "}
          </Col>
          <Col span={7}>
            {" "}
            <DatePicker.RangePicker
              locale={locale}
              format={"DD/MM/YYYY"}
              className={style["filter-input"]}
              presets={rangePresets}
              onChange={onRangeChangeAllData}
            />
          </Col>
          <Col span={2}>
            <Button
              disabled = {!allData_filterEndtime}
              onClick={doExportAllData}
              className={style["export-button"]}
            >
              Xuất
            </Button>
          </Col>
        </Row>

        <div className={style["filter-row-title"]}>
          Xuất dữ liệu thống kê nhập kho theo ngày
        </div>
        <Row align={"middle"} gutter={10} className={style["filter-row"]}>
          <Col span={2} className={style["filter-title"]}>
            Phân loại dữ liệu
          </Col>
          <Col span={4}>
            <Select
              className={style["filter-select"]}
              onChange={handleChangeAllDataStatus}
              value={allData_filterStatus}
              dropdownStyle={{
                borderWidth: 1,
                borderColor: " rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
              }}
              options={statusOptions}
              disabled
            />
          </Col>
          <Col span={2} className={style["filter-title"]}>
            Nhà kho
          </Col>
          <Col span={4}>
            <Select
              className={style["filter-select"]}
              onChange={handleChangeAllDataStorage}
              value={allData_filterStorage}
              dropdownStyle={{
                borderWidth: 1,
                borderColor: " rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
              }}
              options={storageOptions}
              disabled
            />
          </Col>
          <Col span={2} className={style["filter-title"]}>
            {" "}
            Thời gian{" "}
          </Col>
          <Col span={3}>
            {" "}
            <DatePicker
              locale={locale}
              format={"DD/MM/YYYY"}
              className={style["filter-input"]}
              value={importData_filterTime}
              onChange={(date, dateString) => set_importData_filterTime(date)}
            />
          </Col>
          <Col span={4}> </Col>
          <Col span={2}>
            <Button
              onClick={doExportImportDataByDate}
              className={style["export-button"]}
            >
              Xuất
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default XuatBaoCao;

const statusOptions = [
  {
    value: "1",
    label: "Đã hoàn thành",
  },
  {
    value: "2",
    label: "Sắp hoàn thành",
  },
  {
    value: "3",
    label: "Chưa hoàn thành",
  },
];

const storageOptions = [
  {
    value: "1",
    label: "Kho tôn cuộn",
  },
];
