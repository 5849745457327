export const MISA_NAME_ACRONYM = [
  {
    label: "Sản xuất ống",
    value: "B",
  },
  {
    label: "Xẻ hàng",
    value: "SLT",
  },
  {
    label: "Xuất bên ngoài",
    value: "SR",
  },
];
