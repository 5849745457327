import { Layout, Menu } from "antd";
import { Logo, LogoNsmv, LogoText, NSMVLogo, NSMVLogoIcon } from "../../../assets/svg";
import mainSider from "./mainSider.module.less";
import cn from "classnames";
import siderItems from "./siderItems";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { useSelector } from "react-redux";
import { LogoSider } from "../../../assets/img";

const MainSider = ({ collapsed = false }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [currentKeys, setCurrentKeys] = useState([]);
  const [currentRole, set_currentRole] = useState('');

  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if(!!auth.user_data){
      set_currentRole(auth.user_data.role)
    }else{
      set_currentRole('')
    }
    
  },[auth])

  useEffect(() => {
      for (let i = 0; i < siderItems.length; i++) {
        const item = siderItems[i];

        if (item.path === pathname) {
          setCurrentKeys([item.key]);
          break;
        }

        if (item.subs.length > 0) {
          for (let j = 0; j < item.subs.length; j++) {
            const subItem = item.subs[j];
            if (subItem.path === pathname) {
              setCurrentKeys([item.key, subItem.key]);
              break;
            }
            if (subItem.subs.length > 0) {
              for (let k = 0; k < subItem.subs.length; k++) {
                const subSubItem = subItem.subs[k];
                if (subSubItem.path === pathname) {
                  setCurrentKeys([item.key, subItem.key, subSubItem.key]);
                  break;
                }
              }
            }
          }
        }
      }
  }, [pathname]);

  const handleClick = (val) => {
    setCurrentKeys(val.keyPath);
  };

  const handleClickItem = (val) => {
    history.push(paths[`${val.key}`]);
  };

  const getItem = (label, key, icon, children, type) =>({
      key,
      icon,
      children,
      label,
      type,
    })
  
  const getMenuItem = (items) => { 
    return items.filter(item => !item.roles || item.roles.length == 0 || item.roles.includes(currentRole)).map(item => getItem(item.title, item.key,item.icon, (!!item.subs && item.subs.length > 0) ? getMenuItem(item.subs) : null  ))
  }
  
  return (
    <Layout.Sider
      className={cn(
        mainSider["container"],
        collapsed && mainSider["collapsed"]
      )}
      collapsed={collapsed}
      width={300}
    >
      <div className={mainSider["topbar"]}>
        {collapsed ? <NSMVLogoIcon /> : <img src={LogoSider} style={{width: '100%', objectFit: 'contain'}}/>}
      </div>

      <Menu
        theme="dark"
        mode="inline"
        className={mainSider["menu"]}
        onClick={handleClick}
        selectedKeys={currentKeys}
        // openKeys={currentKeys}
        items = {getMenuItem(siderItems)}
        onSelect={handleClickItem}
      />
    </Layout.Sider>
  );
};

export default MainSider;
