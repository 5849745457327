import { Button, Flex, Modal, Table, notification } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import AxiosService from '../../../../../common/AxiosService';
import { apis } from '../../../../../constants';
import { formatWeightText } from '../../../../../helpers';
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined, FormOutlined } from '@ant-design/icons';
import style from "./exportDetailModal.module.less";
import moment from 'moment';


const ExportDetailModal = forwardRef((props, ref) => {

    const {reloadData = () => {}} = props;

    const [isModalOpen, set_isModalOpen] = useState(false);
    const [data, set_data] = useState([])
    const [status, set_status] = useState('')
    const [modal, contextHolder] = Modal.useModal();
    const [dataId, set_dataId] = useState('')
   
    useImperativeHandle(ref, () => ({
        openModal(requestId, status) {
            set_isModalOpen(true)
            loadRequestDetail(requestId)
            set_status(status)
            set_dataId(requestId)
        },

    }));

    const loadRequestDetail = (requestId) => {
        let dataRequest = {
            id: requestId
        }
        AxiosService.post(apis.Url_Api_Get_Coil_Export_Request_Detail, dataRequest)
            .then(response => {
                let { data } = response;
                if (!!data && data.message === "OK") {
                    console.log(data);
                    set_data(data.data)
                }
            })
            .catch(err => console.log(err))
    }

   
    const handleOk = () => {
        set_isModalOpen(false);
    };
    const handleCancel = () => {
        set_isModalOpen(false);
        set_data([])
        set_status('')
    };

    
      const requestConfirmExport = () => {
        let dataRequest = {
          "listIdDeliver" : [
            dataId
         ]
        }
        AxiosService.post(apis.Url_Api_Post_XacNhanChiThiXuatKho, dataRequest)
        .then(res => {
          let { data } = res;
          if (!!data && data.message === "OK") {
            notification.success({message: "Xác nhận thành công"})
          } else {
            notification.error({message: data.message})
          }
          reloadData()
          handleCancel()
        })
        .catch(e => notification.error({message: e.message}))
      }

    const onRequestDelele = () => {
            modal.confirm({
              title: 'Xác nhận xóa',
              icon: <ExclamationCircleOutlined />,
              content: 'Bạn chắc chắn muốn xóa chỉ thị này?',
              okText: 'Xóa',
              okType: 'danger',
              onOk : () => onsendRequestCancel(),
              cancelText: 'Đóng',
            });
      
    }

    const onsendRequestCancel = (item) => {
        let dataRequest = {
            "listCancelId" : [dataId],
          "reasonCancel" : "test hủy chị thị kho"
      }
        AxiosService.post(apis.Url_Api_Post_HuyChiThiXuatKho, dataRequest)
        .then(res => {
          let { data } = res;
          console.log(res);
          if (!!data && data.message === "OK") {
            notification.success({message: "Xóa thành công"})
          } else {
            notification.error({message: data.message})
          }
          reloadData()
          handleCancel()
        })
        .catch(e => notification.error({message: e.message}))
      }


    return <Modal title="CHI TIẾT CHỈ THỊ XUẤT KHO" closeIcon = {false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className={style['modal-container']} width={'80vw'}
    
    footer={[
        <Button key="back" onClick={handleCancel}>
          Đóng
        </Button>,
         <Button key="cancel" type="danger" onClick={onRequestDelele} className={(status != '2' && status != 3) && style['hidden']}>
          Hủy
        </Button>,
        <Button type='primary' key = 'submit'
          onClick={requestConfirmExport}
          className={(status != '2') && style['hidden']}>
          Phê duyệt
        </Button>,
      ]}
    >
        <Table columns={columns} dataSource={data} className={style['table']} pagination={{ position: ['none', 'none'], pageSize: 100 }}
            rowKey="rollId" rowClassName={style['table-row']}
        />
        {contextHolder}
    </Modal>
});

export default ExportDetailModal;

const getStatusLabel = (status) => {

    switch (status) {
        case '2':
            return "Chờ Duyệt";
        case '1':
            return "Hoàn Thành"
        case '3':
            return "Đã Duyệt/Chờ Xuất";
        default:
            return "Chờ Duyệt";
    }
}

const columns = [
    {
        title: 'Ngày nhập',
        dataIndex: 'importDate',
        key: 'importDate',
        width: '120px',
        render: (text) => <div>{text}</div>,
    },
    {
        title: 'Số cuộn',
        dataIndex: 'nsmvCoilId',
        key: 'nsmvCoilId',
        width: '200px',
        render: (value) => <div className={style["important-text"]}>{value}</div>
    },

    {
        title: 'Mác Thép',
        dataIndex: 'steelGrade',
        key: 'steelGrade',
        width: '150px',
        render: (totalRoll, item) => totalRoll
    },

    {
        title: 'Chủng loại',
        dataIndex: 'steelType',
        key: 'steelType',
        width: '150px',
        render: (totalRoll, item) => totalRoll
    },
    {
      title: <div><div>Dày x Rộng</div><div style={{paddingLeft: '18px'}}>(mm)</div></div>,
        dataIndex: 'size',
        key: 'size',
        width: '150px',
        render: (totalRoll, item) => (item.sizeDepth.toString().includes(".") ? item.sizeDepth : item.sizeDepth + ".0") + " × " + (item.sizeWidth.toString().includes(".") ? item.sizeWidth : item.sizeWidth + ".0")
    },

    {
      title: <div><div>Trọng Lượng</div><div style={{paddingLeft: '25px'}}>(Kg)</div></div>,
        dataIndex: 'netWeight',
        key: 'netWeight',
        width: '250px',
        render: (totalNetWeight) => formatWeightText(totalNetWeight)
    },
    
    {
      title: <div><div>Ngày Xuất</div><div style={{paddingLeft: '25px'}}></div></div>,
        dataIndex: 'deliverDate',
        key: 'deliverDate',
        width: '250px',
        render: (deliver_date) => <div>{deliver_date}</div>
    },
    {
        title: 'Nhà Cung Cấp',
        dataIndex: 'providerName',
        key: 'providerName',
        width: '250px',
        // render: (totalNetWeight) => totalNetWeight
    },
    {
        title: 'Ghi Chú',
        width: '200px',
        dataIndex: 'note',
        key: 'note',
    },
    // {
    //     title: 'Trạng Thái',
    //     dataIndex: 'status',
    //     key: 'status',
    //     width: '150px',
    //     render: (value) => getStatusLabel(value)
    // }
];