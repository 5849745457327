import Axios from "axios";
import { keys } from "../constants";
import { HLog, localGet } from "../helpers";
class AxiosService {
  constructor() {
    const instance = Axios.create();
    instance.interceptors.response.use(this.handleSucess, this.handleError);
    this.instance = instance;
  }

  handleSucess(res) {
    return res;
  }

  handleError(e) {
    HLog(e);
    // if (e.response.data.code === 15) {
    //   localStorage.removeItem('Authorization')
    //   localStorage.removeItem('userInfo')
    //   window.location.reload();
    // }
    return Promise.reject(e);
  }

  async get(url) {
    try{
      
      return this.instance.get(url, {
        headers: {
          Authorization: await localGet(keys.access_token),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    }catch(e){}
  }

  async post(url, body) {
    HLog("post url",url );
    HLog("post body",body );
    let header =  {
      Authorization: await localGet(keys.access_token),
      "Content-Type": "application/json",
      Accept: "application/json",
    }
    HLog(header);
    return this.instance.post(url, body, {
      headers: header,
    });
  }

  async put(url, body) {
    return this.instance.put(url, body, {
      headers: {
        Authorization: await localGet(keys.access_token),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  async delete(url) {
    return this.instance.delete(url, {
      headers: {
        Authorization: await localGet(keys.access_token),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  
  async download(url) {
    try{
    return this.instance.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: await localGet(keys.access_token),
      },
    });
    }catch(e){
      HLog(e.message);
    }
  }
  
}
export default new AxiosService();
