import { createSlice } from "@reduxjs/toolkit";
import { localSet } from "../../helpers";
import { keys } from "../../constants";

const initState = {
  isOk: undefined,
  message: undefined,
  user: undefined,
  token: undefined, 
  isLoading: false,
  user_data: undefined,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    doLogin(state) {
      return { ...state, isOk: undefined, message: undefined, isLoading: true };
    },
    doLogout() {
      localSet(keys.access_token, undefined);
      localSet(keys.user_data, "");
      localSet(keys.key_loginTime, "");
      return initState ;
    },

    authSuccess(state, action) {
      return action.payload;
    },

    authFail(state, action) {
      return action.payload;
    },

    resetAuthMessage(state) {
      return { ...state, isOk: undefined, message: undefined };
    },

    authRestore(state, {payload}) {
      return { ...state , ...payload};
    },
  },
});

export const { doLogin, doLogout, authSuccess, authFail, resetAuthMessage, authRestore } =
  authSlice.actions;

export default authSlice.reducer;
