import { Button, Flex, Input, Layout, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import style from './login.module.less'
import { headerLogo, loginBanner, logoNSMP } from '../../../assets/img';
import { EyeInvisibleOutlined, EyeOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { paths } from '../../../constants';
import { doLogin } from '../../../ducks/slices/authSlice';
import { doInitMasterData } from '../../../ducks/slices/appSlice';

const Login = (props) => {
    const [username, set_username] = useState('');
    const [usernameError, set_usernameError] = useState('');
    const [password, set_password] = useState('');
    const [passwordError, set_passwordError] = useState('');
    const [passwordVisible, set_passwordVisible] = useState(false);

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const history = useHistory()

    useEffect(() => {
        let current = new Date().getTime()
        if (!!auth.token && current < auth.loginTime + 86400000) {
            history.push(paths.main_tong_quan)
        }
    }, [auth, history])


    const onPressLogin = () => {
        if (!!username && !!password) {
            dispatch(doLogin({username, password}))
        } else {
            if (password === '') {
                set_passwordError("Hãy nhập mật khẩu.")
            }

            if (username === '') {
                set_usernameError("Hãy nhập tên đăng nhập.")
            }
        }
    }

    const onChangePassword = (val) => {
        set_password(val)
        set_passwordError("")
    }

    const onChangeUsername = (val) => {
        set_username(val)
        set_usernameError("")
    }

    const keyPressHandler = (event) => {

        if (event.keyCode === 13) {
            onPressLogin();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', keyPressHandler);
        return () => {
            document.removeEventListener('keydown', keyPressHandler);
        };
    });


    const onForgotPassword = () => {
        history.push(paths.quen_mat_khau)
    }

    return (<div className={style["flex-container"]}>
        <Flex vertical={false}>
            <div className={style["base-layout"]}>
                <img src={loginBanner} width={'100%'} height={'100%'} />
            </div>
            <Layout className={style["base-layout"]}>
                <Layout.Header className={style["layout-header"]}>
                    <Flex align='center' style={{ height: '100%' }}>
                        <img src={headerLogo} className={style["logo-header"]} />
                    </Flex>
                </Layout.Header>
                <Layout.Content>
                    <div className={style["layout-content"]}>
                        <div className={style["login-box"]}>
                            <div className={style["login-title"]}>
                                ĐĂNG NHẬP

                            </div>
                            <div className={style["login-form"]}>
                                Tên đăng nhập
                                <Input className={style["login-input"]}   type="username"  id="username" onChange={({ target }) => onChangeUsername(target.value)} />
                                <div className={style["error-input"]}>{usernameError}</div>
                            </div>

                            <div className={style["login-form"]}>
                                <Row justify={'space-between'}>
                                    <div>Mật khẩu</div>
                                    <div onClick={onForgotPassword} className={style["forgot-password"]}>Quên mật khẩu?</div>
                                </Row>
                                <Input.Password className={style["login-input"]} value={password} onChange={({ target }) => onChangePassword(target.value)} security='password'
                                    iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)} />
                                <div className={style["error-input"]}>{passwordError}</div>
                            </div>
                            <div className={style["error-request"]}>{auth.message}</div>
                            <Button className={style["login-button"]} onClick={onPressLogin}>Đăng nhập</Button>

                        </div>
                    </div>
                </Layout.Content>
            </Layout>
        </Flex>
    </div>
    )
};

export default Login;
