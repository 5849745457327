import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, Layout } from "antd";
import header from "./header.module.less";
import cn from "classnames";
import { Popup } from "../../../components/common";
import { doLogout } from "../../../ducks/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";

const Header = ({
  children,
  collapsedSider = false,
  toggleSider = () => {},
  className,
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  return (
    <Layout.Header
      className={cn(
        header["container"],
        !children && header["main-header"],
        className
      )}
    >
      {!!children ? (
        children
      ) : (
        <>
          <div
            className={cn(header["wrapper"], header["right"])}
            onClick={toggleSider}
          >
            {collapsedSider ? (
              <MenuUnfoldOutlined className={header["icon"]} />
            ) : (
              <MenuFoldOutlined className={header["icon"]} />
            )}
          </div>

          <div className={cn(header["wrapper"], header["grow"])}>
            <Input
              prefix={
                <SearchOutlined
                  className={cn(header["icon"], header["color"])}
                />
              }
              className={header["input"]}
              placeholder="Search"
            />
          </div>

          <Popup
            content={
              <div style={{ textAlign: "center", padding: 20 }}>
                <Button type="link" onClick={() => dispatch(doLogout())}>Đăng xuất</Button>
                <div style={{ margin: "30px 0 10px 0" }}>Version: 0.0.1</div>
              </div>
            }
          >
            <div className={cn(header["wrapper"], header["left"])}>
              <div className={header["name"]}>{!!auth.user_data ? auth.user_data.username : ''}</div>

              <Avatar size={30} className={header["avatar"]}>
                BN
              </Avatar>
            </div>
          </Popup>
        </>
      )}
    </Layout.Header>
  );
};

export default Header;
