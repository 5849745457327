import {
  Button,
  Col,
  DatePicker,
  Flex,
  Input,
  Modal,
  Row,
  Select,
  Table,
  notification,
} from "antd";
import React, { forwardRef, useState } from "react";
import style from "./taoChiThiXuatKhoModal.module.less";
import { MISA_NAME_ACRONYM } from "../../../constants/utils";
import dayjs from "dayjs";

const TaoChiThiXuatKhoModal = forwardRef((props, ref) => {
  const { isOpenModal, setIsOpenModal, onClickSave, isPressCreateTicket , isApprove  , setIsApprove } =
    props;

  const [misaNameAcronym, setMisaNameAcronym] = useState("SR");
  const [deliverDate, setDeliverDate] = useState(null);

  const handleOk = () => {
    setIsOpenModal(false);
    setIsApprove(false)
  };

  const handleCancel = () => {
    setMisaNameAcronym("SR");
    setDeliverDate("")
    setIsApprove(false)
    setIsOpenModal(false);
  };

  const handleSave = () => {
    onClickSave(misaNameAcronym,deliverDate, isPressCreateTicket);
  };

  const handleDeliverDate = (date , dateString) =>{
    const deliverDate = dayjs(date).format('DD/MM/YYYY') || null
    setDeliverDate(deliverDate)
  }

  const title = (
    <h1 className={style["title"]}>
      Xuất kho thực tế
    </h1>
  );
  return (
    <Modal
      title={title}
      closeIcon={false}
      open={isOpenModal}
      onOk={handleOk}
      onCancel={handleCancel}
      className={style["modal-container"]}
      width={"550px"}
      footer={[]}
    >
      <div className={style["main"]}>
        <Row gutter={[10, 20]} align={"middle"} justify={"space-between"} style={{ marginTop: "20px" }}>
          {/* <Col span={4} className={style["filter-title"]}>
            Chủng loại
          </Col> */}
          <Col span={10} >
            <label  className={style['filter-title']} >Mục đích sử dụng</label>
            <Select
              className={style["filter-select"]}
              style={{marginTop : "10px"}}
              onChange={setMisaNameAcronym}
              defaultValue={"SR"}
              value={misaNameAcronym}
              showSearch
              optionFilterProp="label"
              dropdownStyle={{
                borderWidth: 1,
                borderColor: " rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
              }}
              options={MISA_NAME_ACRONYM}
            />
          </Col>
          {isApprove && (
          <Col span={10}>
          <label  className={style['filter-title']} >Ngày xuất kho thực tế</label>
          <DatePicker format={"DD/MM/YYYY"}  className={style['filter-input']}   style={{marginTop : "10px"}} onChange={handleDeliverDate}/>
          </Col>
          )}
        </Row>
        <Row
          align={"middle"}
          style={{ marginTop: "20px" }}
          gutter={[20]}
          justify={"end"}
        >
          <Col>
            <Button key="back" onClick={handleCancel}>
              Đóng
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={handleSave}>
              Lưu
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
});

export default TaoChiThiXuatKhoModal;
