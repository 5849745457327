import { takeLatest, call, put } from "redux-saga/effects";
import { common_post, HLog, localRemove, localSet } from "../../helpers";
import { authFail, authSuccess, doLogin, doLogout } from "../slices/authSlice";
import { apis, keys } from "../../constants";
import AxiosService from "../../common/AxiosService";
import { doInitMasterData } from "../slices/appSlice";

export function* watchDoAuth() {
  yield takeLatest(doLogin.type, handleLogin);
  yield takeLatest(doLogout.type, handleLogout);
}

export function* handleLogin(action) {
  HLog("Call handle login", action);

  try {

    const response = yield AxiosService.post(apis.Url_Api_Login, action.payload)

    HLog("Login response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
      let loginTime = new Date().getTime();
      data.loginTime = loginTime;
      localSet(keys.access_token, data.accessToken);
      localSet(keys.user_data, data);
      localSet(keys.key_loginTime, loginTime);

      yield put(
        authSuccess({
          isOk: true,
          token: data.accessToken,
          isLoading: false,
          user_data: data,
          ...data
        })
      );
      yield put(
        doInitMasterData() //Gọi saga load data master
      );
    } else {
      yield put(
        authFail({
          isOk: false,
          message: response.data.message === keys.api_login_fail_user ? 'Tên đăng nhập hoặc mật khẩu không đúng.' : 'Lỗi hệ thống',
          isLoading: false,
          user: undefined,
        })
      );
    }
  } catch (error) {
    HLog("Login Error", error);

    yield put(
      authFail({
        isOk: false,
        message: "Lỗi hệ thống",
        isLoading: false,
        user: undefined,
      })
    );
  }
}

export function* handleLogout() {
  yield localRemove(keys.access_token);
  yield localRemove(keys.user_data);

  window.location.reload();
}
