import { takeLatest, call, put } from "redux-saga/effects";
import { common_post, HLog, localRemove, localSet } from "../../helpers";
import { apis, keys } from "../../constants";
import AxiosService from "../../common/AxiosService";
import { doInitMasterData, setAppProperties } from "../slices/appSlice";

export function* watchAppSaga() {
  yield takeLatest(doInitMasterData.type, handleLoadSteelTypeMaster);
  yield takeLatest(doInitMasterData.type, handleLoadMasterShift);
  yield takeLatest(doInitMasterData.type, handleLoadMasterTeam);
  yield takeLatest(doInitMasterData.type, handleLoadMasterCustomerType);
}

export function* handleLoadSteelTypeMaster(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_SteelType, {})

    HLog("handleLoadSteelTypeMaster response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      steelTypeMaster:   [{ value: '' , label: 'Tất cả' }].concat(data.map(item  => ({ value: item.typeAcronym , label: item.steelTypeName }))) 
    }))
    } else {
      yield put( setAppProperties({
        steelTypeMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadSteelTypeMaster Error", error);
  }
}

export function* handleLoadMasterShift(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_Shift, {})

    HLog("handleLoadMasterShift response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      shiftMaster:  [{ value: '' , label: 'Tất cả' }].concat(data.map(item  => ({ value: item.shiftWorkName , label: item.shiftWorkName })))
    }))
    } else {
      yield put( setAppProperties({
        shiftMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterShift Error", error);
  }
}

export function* handleLoadMasterTeam(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_Team, {})

    HLog("handleLoadMasterTeam response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      teamMaster: [{ value: '' , label: 'Tất cả' }].concat(data.map(item  => ({ value: item.teamWorkName , label: item.teamWorkName })))
    }))
    } else {
      yield put( setAppProperties({
        teamMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterTeam Error", error);
  }
}

export function* handleLoadMasterCustomerType(action) {
  try {
    const response = yield AxiosService.post(apis.Url_Api_Get_MasterData_CustomerType, {})

    HLog("handleLoadMasterCustomerType response", response);
    const { data } = response.data;
    if (!!data && response.data.status === keys.api_request_status_success_code) {
     yield put( setAppProperties({
      customerMaster: [{ value: '' , label: '' }].concat(data.map(item  => ({ value: item.nameAcronym , label: item.name })))
    }))
    } else {
      yield put( setAppProperties({
        customerMaster: []
      }))
    }
  } catch (error) {
    HLog("handleLoadMasterCustomerType Error", error);
  }
}


