import { Button, Col,Input, Modal, Row, notification } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import AxiosService from '../../../../../common/AxiosService';
import { apis } from '../../../../../constants';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import style from "./xetDuyetChiThiNhap.module.less";
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { appLoading } from '../../../../../ducks/slices/appSlice';

const XetDuyetChiThiNhap = forwardRef((props, ref) => {

    const {reloadData = () => {}} = props;

    const [isModalOpen, set_isModalOpen] = useState(false);
    const [data, set_data] = useState([])
    const [listMail, set_listMail] = useState([{mail: ''}])
    const app = useSelector(state => state.app)
    const dispatch = useDispatch()
  

    useImperativeHandle(ref, () => ({
        openModal(data) {
            set_isModalOpen(true)
            initModalData(data)
            // loadRequestDetail(requestId)
        },

    }));

    const initModalData = (data) => {
        console.log(data);
        set_data(data)
    }
   
    const handleOk = () => {
        set_isModalOpen(false);
    };
    const handleCancel = () => {
        set_isModalOpen(false);
        set_listMail([{mail: ''}])
    };

    const validateMailForm = () => {
        // let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        // let regex = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/gm;
        
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validatedList = listMail.map(item => ({...item, isError: !regex.test(item.mail) && item.mail !== ''}))
        set_listMail(validatedList)
        console.log(validatedList);
        if(validatedList.some(item => item.isError)){
            return false
        }else{
            return true
        }
    }

    
      const requestConfirm = (item) => {
        dispatch(appLoading(true))
      if(!validateMailForm()){
        dispatch(appLoading(false))
        return;
      }
        let dataRequest = {
            "lstRollId" : data.map(item => item.id),
            "isAccess" : true,
            "listMail" : listMail.filter(item => item.mail !== '' && !item.isError).map(item => item.mail)
        }
        console.log(dataRequest, listMail);
        AxiosService.post(apis.Url_Api_Post_PheDuyetChiThiXuatKho, dataRequest)
        .then(res => {
          let { data } = res;
          if (!!data && data.message === "OK") {
            notification.success({message: "Phê duyệt thành công"})
            reloadData();
            handleCancel();
            dispatch(appLoading(false))
          } else {
            notification.error({message: data.message})
            dispatch(appLoading(false))
          }
        })
        .catch(e => {
          dispatch(appLoading(false))
          notification.error({message: e.message})
        })
      }

    return <Modal
     title="GỬI MAIL ĐẾN BỘ PHẬN LIÊN QUAN" 
     closeIcon = {false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className={style['modal-container']} width={'700px'}
    
    footer={[
        <Button key="back" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button type='primary'
          onClick={requestConfirm}
        >
          Duyệt & Gửi
        </Button>
      ]}
    >
        <div className={style['main']}>
        {listMail.filter(i => true).map((item,index) => (<Row align={'middle'} style={{marginTop: '10px'}}>
        <Col span={5} className={style['filter-title']}>Người nhận {index + 1}:</Col>
        <Col span={15}><Input className={cn([style['filter-input'], !!item.isError && style['error-form']])} style={{color: '#000'}} required value={item.mail} onChange={({ target }) => {
           set_listMail( current => current.map((item, i) => i === index? {...item, mail: target.value} : item))
            
          }} /></Col>
          {listMail.length > 1 && <Col span={2} style={{display: 'flex', justifyContent: 'center'}}><MinusCircleOutlined style={{fontSize: '20px', color: '#f27e7c'}} onClick={() => {set_listMail( current => current.filter((item, iindex) => iindex != index))}} /></Col>}
          {index === listMail.length -1 && <Col span={2} style={{display: 'flex', justifyContent: 'center'}}><PlusCircleOutlined style={{fontSize: '20px', color: '#2c3782'}} onClick={() => {set_listMail( current => current.concat([{mail: ''}]))}} /></Col>}
         
        </Row>))}
        {listMail.some(item => !!item.isError) && <div style={{fontWeight: 'bold', color: '#f27e7c', textAlign: 'center'}}>Vui lòng nhập đúng email!</div>}
        </div>
    </Modal>
});

export default XetDuyetChiThiNhap;
