import { Button, Flex, Input, Layout, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import style from './quenmatkhau.module.less'
import { headerLogo, loginBanner, logoNSMP } from '../../../assets/img';
import { EyeInvisibleOutlined, EyeOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { apis, keys, paths } from '../../../constants';
import AxiosService from '../../../common/AxiosService';
import cn from 'classnames';

const QuenMatKhau = (props) => {
  const [username, set_username] = useState('');
  const [usernameError, set_usernameError] = useState('');
  const [sentSuccess , set_sentSuccess] = useState(false)

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const history = useHistory()

  useEffect(() => {
    let current = new Date().getTime()
    if (!!auth.token && current < auth.loginTime + 86400000) {
      history.push(paths.main_tong_quan)
    }
  }, [auth, history])


  const onPressQuenMatKhau = () => {
    if (!!username) {
      let requestBody = {
        account: username
      }
      AxiosService.post(apis.Url_Api_QuenMatKhau, requestBody)
        .then(response => {
          let { data } = response;
          console.log(data);
          if (!!data && data.message === "OK") {
            set_sentSuccess(true)
          } else if (data.message === keys.api_message_EMPTY) {

          } else if (data.message === keys.api_message_USER_NOTFOUND) {
            set_usernameError("Tài khoản không tồn tại")
          }
        })
        .catch(err => console.log(err))
    }else{
      set_usernameError("Vui lòng nhập email hoặc tên đăng nhập")
    }
  }

  const onChangeUsername = (val) => {
    set_username(val)
    set_usernameError("")
  }

  const onPressToLogin = () => {
    history.push(paths.dang_nhap)
  }

  return (<div className={style["flex-container"]}>
    <Layout className={style["base-layout"]}>
      <Layout.Header className={style["layout-header"]}>
        <Flex align='center' style={{ height: '100%' }}>
          <img src={headerLogo} className={style["logo-header"]} />
        </Flex>
      </Layout.Header>
      <Layout.Content>
        <div className={style["layout-content"]}>
          {!sentSuccess ? <div className={style["form-box"]}>
            <div className={style["form-title"]}>
              QUÊN MẬT KHẨU

            </div>
            <div className={style["form-layout"]}>
              Vui lòng nhập email hoặc tên đăng nhập
              <Input className={cn(style["form-input"], !!usernameError && style["error"])} value={username} onChange={({ target }) => onChangeUsername(target.value)} />
              <div className={style["error-input"]}>{usernameError}</div>
            </div>
            <div className={style["error-request"]}>{auth.message}</div>
            <Button className={style["form-button"]} onClick={onPressQuenMatKhau}>Xác nhận</Button>

          </div> : 
          <div className={style["form-box"]}>
          <div className={style["form-title"]}>
            QUÊN MẬT KHẨU

          </div>
          <div className={style["form-layout"]}>
            Yêu cầu đổi mật khẩu đã gửi thành công!
            <div>
              Vui lòng kiểm tra email
            </div>
          </div>
          <div className={style["error-request"]}>{auth.message}</div>
          <Button className={style["form-button"]} onClick={onPressToLogin}>Về trang đăng nhập</Button>

        </div>}
        </div>
      </Layout.Content>
    </Layout>
  </div>
  )
};

export default QuenMatKhau;
