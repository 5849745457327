import { Breadcrumb, Button, DatePicker, Row, Select, Column, Col, Input, Table, Flex, Pagination, notification } from "antd";
import { Link } from "react-router-dom";
import { apis, keys, paths } from "../../../../constants";
import style from "./danhSachChiThiKiemKe.module.less";
import { DeleteOutlined, DownloadOutlined, FormOutlined, PlusOutlined, SearchOutlined, TagFilled } from "@ant-design/icons";
import { IconEraser } from "../../../../assets/svg";
import { useEffect, useRef } from "react";
import AxiosService from "../../../../common/AxiosService";
import { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { checkEmptyData, formatWeightText, localGet } from "../../../../helpers";
import cn from 'classnames'
import dayjs from 'dayjs'
import configs from "../../../../constants/configs";
import { appLoading } from "../../../../ducks/slices/appSlice";
import { useDispatch } from "react-redux";

const DanhSachChiThiKiemKe = () => {
  const [currentDataRequest, set_currentDataRequest] = useState({})
  const [currentPage, set_currentPage] = useState(1);

  const history = useHistory();
  const dateInput = useRef();

  const [orderIdOptions, set_orderIdOptions] = useState([{value: '', label: ''}])
  const [orderId, set_orderId] = useState('')

  const [warehouseCode, set_warehouseCode] = useState('1')
  const [warehouseName, set_warehouseName] = useState('Tôn cuộn')
  const [companyName, set_companyName] = useState('A01')
  
  const dispatch = useDispatch();
  const [dataCoil, set_dataCoil] = useState([])
  const [orderData, set_orderData] = useState({})
  const [pageSize, set_pageSize] = useState(10);
  const [loading, set_loading] = useState(true)

  useEffect(() => {
    // initData()
    requestGetOrderDateMasterData()
  }, [])

  const onPressFilter = () => {
  }

  const requestGetData = (dataRequest) => {
  }


  const requestGetOrderDateMasterData = (dataRequest) => {
    dispatch(appLoading(true))
    AxiosService.get(apis.Url_Api_Get_KiemKe_DanhSachMaChiThiKiemKe)
      .then(response => {
        let { data } = response;
        if (!!data && data.message === "OK") {
          if (!!data.data && data.data.length > 0) {
            set_orderIdOptions(data.data.map(item => ({value: item, label: item})))
            set_orderId(data.data[0])
            getResult(data.data[0])
          }
        }else if (data.message === keys.api_message_EMPTY) {
          notification.error({message: "Không có dữ liệu"})
        }else{
          notification.error({message: data.message})
        }
        dispatch(appLoading(false))
      })
      .catch(err => {
        console.log(err);
        dispatch(appLoading(false))
        notification.error("Lỗi hệ thống")
      })
  }
 
  const getResult = (date) => {
      let requestBody = {
        "startDate": date,
        "warehouseCode": "1",
        "warehouseName": "Tôn cuộn",
        "companyName": "A01",
        "status": "1",
        "length": 10
    }
    requestGetResult(requestBody)
  }
  const requestGetResult = (requestBody) => {
    set_loading(true)
    AxiosService.post(apis.Url_Api_Get_KiemKe_XemKetQua, requestBody)
      .then(response => {
        let { data } = response;
        if (!!data && data.message === "OK" && !!data.data) {
           set_orderData(data.data)
           set_dataCoil(data.data.inventoryBodyBeans)
           set_orderId(requestBody.startDate)
          }else if (data.message === keys.api_message_EMPTY) {
            notification.error({message: "Không có dữ liệu"})
          }else{
            notification.error({message: data.message})
          }
          set_loading(false)
      })
      .catch(err => {
        console.log(err);
        set_loading(false)
        notification.error("Lỗi hệ thống")
      })
  }

  const onChangePageSize = (val) => {
    set_pageSize(val);
    set_currentPage(1);
  }

  const onChangePage = (val) => {
    set_currentPage(val)
   
  }

  const onPressAdd = () => {
    history.push(paths.main_them_moi_nhap_kho);
  }
  
  const onPressClear = () => {
  }

  const handleChangeOrderId = (data) => {
    getResult(data)
  }

  const onPressDownloadResult = async () => {
    dispatch(appLoading(true))
    fetch(apis.Url_Api_Post_KiemKe_DownloadResult, {
      method: 'POST',
      body: JSON.stringify({
        "startDate": orderId,
        "warehouseCode": "1",
        "warehouseName": "Tôn cuộn",
        "companyName": "A01",
        "status": "1"
    }),
      headers: {
        "Content-Type": "application/json",
        Authorization: await localGet(keys.access_token),
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = "CT_Kiem_ke_" + orderId + ".xlsx";

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
        dispatch(appLoading(false))
      }).catch(error => {
        dispatch(appLoading(false))
        console.log(error);
        notification.error({message: "Không có dữ liệu"})
    });
  }

  return (
    <div className={style["main-container"]}>
      <Breadcrumb items={[{
        title: 'Kiểm kê',
      },
      {
        title: 'Danh sách chỉ thị',
      }]} />

      <div className={style['main']}>
        <Row gutter={[10, 20]} align={'middle'}>
          <Col span={3} className={style['filter-title']}>Ngày kiểm kê</Col>
          <Col span={5}><Select
            className={style['filter-select']}
            onChange={handleChangeOrderId}
            value={orderId}
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={orderIdOptions}
          /></Col>
          <Col span={3} className={style['filter-title']}>Vị trí</Col>
          <Col span={5}><Input className={style['filter-input']} value={warehouseCode} disabled/></Col>
          <Col span={3} className={style['filter-title']}>Kho</Col>
          <Col span={5}><Input className={style['filter-input']} value={warehouseName} disabled/></Col>
          <Col span={3} className={style['filter-title']}>Mã công ty</Col>
          <Col span={5}><Input className={style['filter-input']} value={companyName} disabled/></Col>
          <Col span={3} className={style['filter-title']}>Kết quả</Col>
          <Col span={5}><Input className={style['filter-input']} value={'Hàng tồn kho đã hoàn thành'} disabled/></Col>
          <Col span={8}>
            <Row gutter={10} align={'middle'} justify={'center'} style={{ marginTop: '20px' }}>
              <Col ><Button onClick={onPressFilter} icon={<SearchOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tìm kiếm</Button></Col>
              <Col ><Button onClick={onPressClear} icon={<IconEraser style={{ height: '16px', width: '16px' }} />} className={style['filter-button']}>Xóa</Button></Col>
            </Row>
          </Col>
          
        </Row>

        <Row style={{ marginTop: '40px' }} align={'middle'} justify={'space-between'} className={style['info-block']}>
          <Col flex={'auto'}>
            <div>Tổng thể: Hàng tồn kho đã hoàn thành: {orderData.totalDone}/{orderData.totalWarehouse} - Chưa hoàn thành: {orderData.totalNotDone} </div>
            <div>Kho {orderData.warehouseCode}: Hàng tồn kho đã hoàn thành: {orderData.totalDone}/{orderData.totalWarehouse} - Chưa hoàn thành: {orderData.totalNotDone} </div>

            {/* <div>Kho {orderData.warehouseCode}: Hàng tồn kho đã hoàn thành: {orderData.totalDoneKg}/{orderData.totalWarehouseOfCode} - Chưa hoàn thành: {orderData.totalNotDoneKg} </div> */}
            {/* <div>Kho khác: Hàng tồn kho đã hoàn thành: 150/200 Chưa hoàn thành: 50 </div> */}
          </Col>

            <Col ><Button onClick={onPressDownloadResult} icon={<DownloadOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tải xuống</Button></Col>
            <Col ><Button onClick={() => {history.push(paths.main_them_moi_chi_thi_kiem_ke)}} className={style['filter-button']}>Tạo chỉ thị mới</Button></Col>

        </Row>

        <Table loading = {loading} columns={columns} dataSource={dataCoil.slice((currentPage - 1)*pageSize, currentPage*pageSize)}  className={style['table']} rowKey="id" pagination={{ position: ['none', 'none'], pageSize: pageSize }}/>
        <Row justify={'end'} className={style['pagination']}>
          <Pagination
            locale={{ items_per_page: '' }}
            pageSizeOptions={[5, 10, 20]}
            className={style["pagination-bar"]}
            pageSize={pageSize}
            hideOnSinglePage
            current={currentPage}
            onChange={(index) => onChangePage(index)}
            defaultCurrent={1}
            total={dataCoil.length}
            showSizeChanger = {false}
          />
          <Select value={pageSize} defaultValue={pageSize} options={configs.PageSizes} onChange={onChangePageSize} style={{ marginLeft: '10px' }} />
        </Row>
      </div>
    </div>
  );
};

export default DanhSachChiThiKiemKe;

const columns = [
  {
    title: 'Nhà cung cấp',
    dataIndex: 'producerCode',
    key: 'producerCode',
    width: '115px'
  },
  {
    title: 'Số cuộn',
    dataIndex: 'numberCoilCode',
    key: 'numberCoilCode',
    width: '100px',
    render: (value) => <div className={style["important-text"]}>{value}</div>
  },
  {
    title: 'Mác Thép',
    dataIndex: 'standardSteel',
    key: 'standardSteel'
  },
  {
    title: 'Chủng Loại',
    dataIndex: 'typeSteel',
    key: 'typeSteel'
  },
 
  // {
  //   title: 'Trọng Lượng (Kg)',
  //   dataIndex: 'strongSteelTemp',
  //   key: 'strongSteelTemp',
  //   width: '150px',
  //   render: (value) => formatWeightText(value)
  // },
//   {
//     title: '',
//     dataIndex: 'netWeight',
//     width: '20px',
//     key: 'netWeight',
//     render: (value) => <div>{getTag(value)}</div>
//   },
  {
    title: <div><div>Trọng Lượng</div><div style={{paddingLeft: '25px'}}>(Kg)</div></div>,
    dataIndex: 'strongSteelTemp',
    key: 'strongSteelTemp',
    width: '200px',
    render: (value) => <div >{formatWeightText(value)}</div>
  },
  {
    title: <div><div>Dày x Rộng</div><div style={{paddingLeft: '18px'}}>(mm)</div></div>,
    dataIndex: 'size',
    key: 'size',
    width: '150px',
    render: (size, item) => (item.length.toString().includes(".") ? item.length : item.length + ".0") + " × " + (item.thinkness.toString().includes(".") ? item.thinkness : item.thinkness + ".0")
  },
  {
    title: 'Ngày nhập',
    dataIndex: 'importDate',
    key: 'importDate',
    width: '150px',
    // render: (importDate, item) => moment(importDate).format('DD/MM/YYYY')
  },
  {
    title: 'Số liệu sổ sách',
    dataIndex: 'strongSteelReal',
    key: 'strongSteelReal',
    width: '150px',
    render: (value, item) => formatWeightText(value)
  },
  {
    title: 'Số liệu kiểm kê',
    dataIndex: 'strongInventory',
    key: 'strongInventory',
    width: '150px',
    render: (value, item) => formatWeightText(value)
  },
  {
    title: 'Số chênh lệch',
    dataIndex: 'spreadResults',
    key: 'spreadResults',
    width: '150px',
    render: (value, item) => formatWeightText(value)
  },
  
  {
    title: 'Nguyên nhân chênh lệch',
    dataIndex: 'note',
    key: 'note'
  }

];

const getTag = (weight) => {
  if(Number(weight) < 100){
    return <TagFilled style={{color: '#f27e7c'}}/>
  }else if (Number(weight) < 500){
    return <TagFilled style={{color: '#f6a447'}}/>
  }
  return <div></div>
}

