import { createSlice } from "@reduxjs/toolkit";
import { localSet } from "../../helpers";
import { keys } from "../../constants";

const initState = {
  current_screen_key: '',
  steelTypeMaster: [],
  shiftMaster: [],
  teamMaster: [],
  customerMaster: [],
  loading: false,
  misaNameAcronym: []
};

const appSlice = createSlice({
  name: "app",
  initialState: initState,
  reducers: {
    doInitMasterData(state) {
      return { ...state, 
        steelTypeMaster: [],
        shiftMaster: [],
        teamMaster: [],
        customerMaster: [],
        misaNameAcronym: [] };
        
    },

    changeScreenKey(state, action) {
      return { ...state, ...action.payload };
    },

    setAppProperties(state, action){
      return { ...state, ...action.payload };
    },

    appLoading(state, action){
      return { ...state, loading: action.payload };
    },

  },
});

export const { changeScreenKey, setAppProperties, doInitMasterData, appLoading } =
  appSlice.actions;

export default appSlice.reducer;
