import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import style from "./thongKe.module.less";
import moment from "moment";
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Flex,
  Input,
  Row,
  Select,
  notification,
} from "antd";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/vi_VN";
import "dayjs/locale/vi";
import { apis, keys } from "../../../../constants";
import { checkEmptyData, localGet } from "../../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import AxiosService from "../../../../common/AxiosService";
import { Column } from "@ant-design/plots";
import { appLoading } from "../../../../ducks/slices/appSlice";

const rangePresets = [
  {
    label: "7 ngày trước",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "14 ngày trước",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "1 tháng trước",
    value: [dayjs().add(-1, "month"), dayjs()],
  },
  {
    label: "3 tháng trước",
    value: [dayjs().add(-3, "month"), dayjs()],
  },
  {
    label: "6 tháng trước",
    value: [dayjs().add(-6, "month"), dayjs()],
  },
  // {
  //     label: '1 năm trước',
  //     value: [dayjs().add(-365, 'd'), dayjs()],
  // },
];

const ThongKe = forwardRef((props, ref) => {
  const [data, set_data] = useState([]);
  const [rawData, set_rawData] = useState([]);
  const [typeReport, set_typeReport] = useState(null);
  const [providerName, set_providerName] = useState("");
  const [coilId, set_coilId] = useState("");
  const [steelGrade, set_steelGrade] = useState("");
  const [steelType, set_steelType] = useState("");
  const [sizeThick, set_sizeThick] = useState("");
  const [sizeLong, set_sizeLong] = useState("");
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const [allData_filterStartTime, set_allData_filterStartTime] = useState(
    dayjs().add(-6, "month"),
    dayjs()
  );
  const [allData_filterEndtime, set_allData_filterEndtime] = useState(dayjs());

  const onRangeChangeAllData = (dates, dateStrings) => {
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      set_allData_filterStartTime(dates[0]);
      set_allData_filterEndtime(dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
    } else {
      console.log("Clear");
    }
  };

  useEffect(() => {
    onPressFilter();
  }, []);

  const onPressFilter = async () => {
    set_data([]);
    set_rawData([]);
    try {
      dispatch(appLoading(true));
      AxiosService.post(apis.Url_Api_Post_KiemKe_ThongKe, {
        typeReport,
        productName: providerName,
        numberCoilId: coilId,
        steelGrade,
        steelType,
        steelDepth: checkEmptyData(sizeThick),
        steelWidth: checkEmptyData(sizeLong),
        dateFrom: dayjs(allData_filterStartTime).format("YYYY-MM-DD"),
        dateTo: dayjs(allData_filterEndtime).format("YYYY-MM-DD"),
      })
        .then((response) => {
          let { data } = response;
          console.log(data);
          if (!!data && data.message === "OK") {
            let modifyData = data.data.reduce((prev, cur) => {
              console.log(cur, prev);
              return prev.concat([
                {
                  month: "Tháng " + cur.month,
                  value: cur.numberSteelExport,
                  type: "Đã xuất bán",
                },
                {
                  month: "Tháng " + cur.month,
                  value: cur.numberSteelSaveDraft,
                  type: "Nhập dự kiến",
                },
                {
                  month: "Tháng " + cur.month,
                  value: cur.numberSteelSaveStock,
                  type: "Đã nhập kho",
                },
                {
                  month: "Tháng " + cur.month,
                  value: cur.numberSteelWaitingApprove,
                  type: "Đã xuất chờ duyệt",
                },
                {
                  month: "Tháng " + cur.month,
                  value: cur.numberSteelWaitingExport,
                  type: "Đã xuất dự kiến",
                },
              ]);
            }, []);
            set_data(modifyData);
            set_rawData(data.data);
          } else if (data.message === keys.api_message_EMPTY) {
            set_data([]);
          }
          dispatch(appLoading(false));
        })
        .catch((err) => {
          dispatch(appLoading(false));
          notification.error({ message: "Lỗi hệ thống" });
        });
    } catch (error) {
      notification.error({ message: "Lỗi hệ thống" });
    }
  };

  const onPressExportLabel = async () => {
    try {
      dispatch(appLoading(true));
      fetch(apis.Url_Api_Post_BaoCao_ExportBCThongKe, {
        method: "POST",
        body: JSON.stringify({
          typeReport,
          productName: providerName,
          numberCoilId: coilId,
          steelGrade,
          steelType,
          steelDepth: checkEmptyData(sizeThick),
          steelWidth: checkEmptyData(sizeLong),
          dateFrom: dayjs(allData_filterStartTime).format("YYYY-MM-DD"),
          dateTo: dayjs(allData_filterEndtime).format("YYYY-MM-DD"),
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: await localGet(keys.access_token),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));

          const link = document.createElement("a");
          link.href = url;
          link.download =
            "BaoCaoThongKe_" +
            dayjs(allData_filterStartTime).format("YYYY-MM-DD") +
            "_" +
            dayjs(allData_filterEndtime).format("YYYY-MM-DD") +
            ".xlsx";

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
          dispatch(appLoading(false));
        })
        .catch((error) => {
          dispatch(appLoading(false));
          console.log(error);
          notification.error({ message: "Không có dữ liệu" });
        });
    } catch (error) {
      dispatch(appLoading(false));
      console.log(error);
      notification.error({ message: "Không có dữ liệu" });
    }
  };

  function disabledDate(current) {
    return current > moment();
  }

  const typeReportMapping = {
    "00": "Nhập dự kiến",
    "01": "Đã nhập kho",
    1: "Đã xuất bán",
    2: "Đã xuất chờ duyệt",
    3: "Đã xuất dự kiến",
  };

  // const config = {
  //     // data: typeReport === null ? data : data.filter(item => typeReportMapping[typeReport] === item.type),
  //     data: data,
  //     xField: 'month',
  //     yField: 'value',
  //     seriesField: 'type',
  //     colorField: 'type',
  //     isGroup: true,
  //     columnStyle: {
  //         radius: [20, 20, 0, 0],
  //     },
  // };

  const [config, setConfig] = useState({
    // data: typeReport === null ? data : data.filter(item => typeReportMapping[typeReport] === item.type),
    data: data,
    xField: "month",
    yField: "value",
    seriesField: "type",
    colorField: "type",
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
  });

  const [typeReportTable, setTypeReportTable] = useState(null);

  useEffect(() => {
    setConfig({
      data:
        typeReport === null
          ? data
          : data.filter((item) => typeReportMapping[typeReport] === item.type),
      xField: "month",
      yField: "value",
      seriesField: "type",
      colorField: "type",
      isGroup: true,
      columnStyle: {
        radius: [20, 20, 0, 0],
      },
    });
    setTypeReportTable(typeReport);
  }, [data]);

  return (
    <div className={style["main-container"]}>
      <Breadcrumb
        items={[
          {
            title: "Báo cáo",
          },
          {
            title: "Thống kê",
          },
        ]}
      />
      <div className={style["main"]}>
        <Row gutter={[10, 20]} align={"middle"}>
          <Col span={2} className={style["filter-title"]}>
            Loại báo cáo
          </Col>
          <Col span={4}>
            <Select
              className={style["filter-select"]}
              onChange={set_typeReport}
              defaultValue={null}
              value={typeReport}
              showSearch
              optionFilterProp="label"
              dropdownStyle={{
                borderWidth: 1,
                borderColor: " rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
              }}
              options={[
                { value: null, label: "Tất cả" },
                { value: "00", label: "Nhập dự kiến" },
                { value: "01", label: "Đã nhập kho" },
                { value: "1", label: "Đã xuất bán" },
                { value: "2", label: "Đã xuất chờ duyệt" },
                { value: "3", label: "Đã xuất dự kiến" },
              ]}
            />
          </Col>
          <Col span={2} className={style["filter-title"]}>
            Nhà cung cấp
          </Col>
          <Col span={4}>
            <Input
              className={style["filter-input"]}
              value={providerName}
              onChange={({ target }) => set_providerName(target.value)}
            />
          </Col>
          <Col span={2} className={style["filter-title"]}>
            Số cuộn
          </Col>
          <Col span={4}>
            <Input
              className={style["filter-input"]}
              value={coilId}
              onChange={({ target }) => set_coilId(target.value)}
            />
          </Col>
          <Col span={2} className={style["filter-title"]}>
            Mác thép
          </Col>
          <Col span={4}>
            <Input
              className={style["filter-input"]}
              value={steelGrade}
              onChange={({ target }) => set_steelGrade(target.value)}
            />
          </Col>

          <Col span={2} className={style["filter-title"]}>
            Chủng loại
          </Col>
          <Col span={4}>
            <Select
              className={style["filter-select"]}
              onChange={set_steelType}
              defaultValue={""}
              value={steelType}
              showSearch
              optionFilterProp="label"
              dropdownStyle={{
                borderWidth: 1,
                borderColor: " rgba(0, 0, 0, 0.2)",
                backgroundColor: "white",
              }}
              options={app.steelTypeMaster}
            />
          </Col>
          <Col span={2} className={style["filter-title"]}>
            Dày × Rộng
          </Col>
          <Col span={4}>
            <Flex align="center">
              <Input
                className={style["filter-input"]}
                value={sizeLong}
                onChange={({ target }) =>
                  set_sizeLong(target.value.replace(/[^0-9.]/g, ""))
                }
              />
              <div style={{ marginLeft: "10px", marginRight: "10px" }}>×</div>
              <Input
                className={style["filter-input"]}
                value={sizeThick}
                onChange={({ target }) =>
                  set_sizeThick(target.value.replace(/[^0-9.]/g, ""))
                }
              />
            </Flex>
          </Col>
          <Col span={2} className={style["filter-title"]}>
            Thời gian
          </Col>
          <Col span={10}>
            <DatePicker.RangePicker
              value={[allData_filterStartTime, allData_filterEndtime]}
              disabledDate={disabledDate}
              locale={locale}
              format={"DD/MM/YYYY"}
              className={style["filter-input"]}
              presets={rangePresets}
              onChange={onRangeChangeAllData}
            />
          </Col>
        </Row>

        <Row
          style={{ marginTop: "40px", marginBottom: "40px" }}
          align={"middle"}
          justify="end"
        >
          <Col>
            <Button
              ghost
              className={style["ghost-button"]}
              onClick={onPressExportLabel}
            >
              Tải xuống <DownloadOutlined style={{ color: "#2c3782" }} />
            </Button>
          </Col>
          <Col>
            <Button className={style["export-button"]} onClick={onPressFilter}>
              Kết quả
            </Button>
          </Col>
        </Row>

        {data.length > 0 && (
          <Row justify={"center"}>
            <div style={{ border: "1px solid rgba(0,0,0,0.3)" }}>
              <Column {...config} />
            </div>
          </Row>
        )}

        {rawData.length > 0 && (
          <Row
            style={{
                marginTop: "30px",
                width: !typeReportTable ? '1000px' : '640px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }}
        >
            <Col style={{
                minWidth: !typeReportTable ? '150px' : '320px'
            }}>
              <div className={style["table-cell"]}></div>
              {rawData.map((cur) => (
                <div className={style["table-cell"]}>Tháng {cur.month}</div>
              ))}
            </Col>
            {(!typeReportTable || typeReportTable === "01") && (
              <Col style={{ flex: 1 }}>
                <div className={style["table-cell"]}>Đã nhập kho</div>
                {rawData.map((cur) => (
                  <div className={style["table-cell"]}>
                    {cur.numberSteelSaveStock}
                  </div>
                ))}
              </Col>
            )}
            {(!typeReportTable || typeReportTable === "1") && (
              <Col style={{ flex: 1 }}>
                <div className={style["table-cell"]}>Đã xuất bán</div>
                {rawData.map((cur) => (
                  <div className={style["table-cell"]}>
                    {cur.numberSteelExport}
                  </div>
                ))}
              </Col>
            )}
            {(!typeReportTable || typeReportTable === "3") && (
              <Col style={{ flex: 1 }}>
                <div className={style["table-cell"]}>Đã xuất dự kiến</div>
                {rawData.map((cur) => (
                  <div className={style["table-cell"]}>
                    {cur.numberSteelWaitingExport}
                  </div>
                ))}
              </Col>
            )}
            {(!typeReportTable || typeReportTable === "2") && (
              <Col style={{ flex: 1 }}>
                <div className={style["table-cell"]}>Đã xuất chờ duyệt</div>
                {rawData.map((cur) => (
                  <div className={style["table-cell"]}>
                    {cur.numberSteelWaitingApprove}
                  </div>
                ))}
              </Col>
            )}
            {(!typeReportTable || typeReportTable === "00") && (
              <Col style={{ flex: 1 }}>
                <div className={style["table-cell"]}>Nhập dự kiến</div>
                {rawData.map((cur) => (
                  <div className={style["table-cell"]}>
                    {cur.numberSteelSaveDraft}
                  </div>
                ))}
              </Col>
            )}
          </Row>
        )}
      </div>
    </div>
  );
});

export default ThongKe;
