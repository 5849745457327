import { Breadcrumb, Button, DatePicker, Row, Select, Column, Col, Input, Dropdown, Menu, Table, Tag, Space, Checkbox, Pagination, Flex, notification, Popover } from "antd";
import { Link } from "react-router-dom";
import { apis, keys, paths } from "../../../../constants";
import style from "./xuatKho.module.less";
import { ArrowDownOutlined, DownloadOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { IconEraser } from "../../../../assets/svg";
import { useEffect, useState } from "react";
import { checkEmptyData, formatWeightText, getPullingSampleResult } from "../../../../helpers";
import dayjs from 'dayjs'
import AxiosService from "../../../../common/AxiosService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import configs from "../../../../constants/configs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { appLoading } from "../../../../ducks/slices/appSlice";
import TaoChiThiXuatKhoModal from "../TaoChiThiXuatKhoModal";

const XuatKho = () => {
  const [data, set_data] = useState([]);
  const [total, set_total] = useState(0);
  const [totalWeight, set_totalWeight] = useState(0);

  const [providerName, set_providerName] = useState('')
  const [coilId, set_coilId] = useState('')
  const [steelGrade, set_steelGrade] = useState('')
  const [importDate, set_importDate] = useState()
  const [steelType, set_steelType] = useState('')
  const [netWeight, set_netWeight] = useState('')
  const [size, set_size] = useState('')
  const [sizeWidth, set_sizeWidth] = useState('')
  const [sizeDepth, set_sizeDepth] = useState('')
  const [sizeWidthTo, set_sizeWidthTo] = useState('')
  const [sizeDepthTo, set_sizeDepthTo] = useState('')
  const [currentDataRequest, set_currentDataRequest] = useState({})
  const [currentPage, set_currentPage] = useState(1);
  const [status, set_status] = useState('')
  const [selectedRecord, set_selectedRecord] = useState([])
  const history = useHistory();

  const [pageSize, set_pageSize] = useState(10);
  const [tableConfig, set_tableConfig] = useState(configSample)
  const [openConfig, set_openConfig] = useState(false)

  const [currentRole, set_currentRole] = useState('');
  const [loading, set_loading] = useState(true)
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const app = useSelector(state => state.app)

  const [isApprove , setIsApprove] = useState(false)

  useEffect(() => {
    if(!!auth.user_data){
      set_currentRole(auth.user_data.role)
    }else{
      set_currentRole('')
    }
    
  },[auth])


  const handleChangeType = (e) => { set_steelType(e) }

  const handleChangeStatus = (e) => { set_status(e) }

  useEffect(() => {
    initData()
  }, [])

  const onPressClearFilter = () => {
    set_providerName('')
    set_coilId('')
    set_steelGrade('')
    set_importDate()
    set_steelType('')
    set_netWeight('')
    set_size('')
    set_sizeWidth('')
    set_sizeDepth('')
    set_sizeWidthTo('')
    set_sizeDepthTo('')
  }

  const initData = () => {
    let dataRequest = {
      start: 0,
      length: pageSize,
      draft: null,
      status: "5",
      providerName: null,
      steelType: null,
      rollNsmvId: null,
      steelGrade: null,
      importDate: null,
      netWeight: null,
      sizeWidth: null,
      sizeDepth: null,
      saveInStock: 1,
      sortRequest: {
        "importDate" : "desc",
        "nsmvCoilId" : "asc",
        "steelGrade" : null,
        "steelType" : null,
        "sizeDepth" : null,
        "sizeWidth" : null,
        "netWeight" : null,
        "temWeight": null,
        "providerName" : null,
        "producerCode" : null,
        "note" : null
      }
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const getSizeRangeValidate = () => {
    let requestSize = {
      sizeWidth:'',
      sizeDepth:'',
      sizeWidthTo:'',
      sizeDepthTo:'',
    }

    if(sizeDepth == '' && sizeDepthTo != ''){
      requestSize.sizeDepth = "";
      requestSize.sizeDepthTo = sizeDepthTo;
      // set_sizeDepth(sizeDepthTo)
      // set_sizeDepthTo('')
    }else if(sizeDepthTo == '' && sizeDepth != ''){
      requestSize.sizeDepth = sizeDepth;
    }else if(sizeDepthTo != '' && sizeDepth != ''){
      if(Number(sizeDepthTo) < Number(sizeDepth)){
        set_sizeDepth(sizeDepthTo)
        set_sizeDepthTo(sizeDepth)
        requestSize.sizeDepth = sizeDepthTo;
        requestSize.sizeDepthTo = sizeDepth;
      }else{
        requestSize.sizeDepth = sizeDepth;
        requestSize.sizeDepthTo = sizeDepthTo;
      }
    }else{
      //do nothing
    }

    if(sizeWidth == '' && sizeWidthTo != ''){
      requestSize.sizeWidth = "";
      requestSize.sizeWidthTo = sizeWidthTo;
      // set_sizeWidth(sizeWidthTo)
      // set_sizeWidthTo('')
    }else if(sizeWidthTo == '' && sizeWidth != ''){
      requestSize.sizeWidth = sizeWidth;
    }else if(sizeWidthTo != '' && sizeWidth != ''){
      if(Number(sizeWidthTo) < Number(sizeWidth)){
        set_sizeWidth(sizeWidthTo)
        set_sizeWidthTo(sizeWidth)
        requestSize.sizeWidth = sizeWidthTo;
        requestSize.sizeWidthTo = sizeWidth;
      }else{
        requestSize.sizeWidth = sizeWidth;
        requestSize.sizeWidthTo = sizeWidthTo;
      }
    }else{
      //do nothing
    }

    return requestSize;
  }

  const onPressFilter = () => {
    let sizeRangeValidate = getSizeRangeValidate();
    set_currentPage(1)
    let dataRequest = {
      start: 0,
      length: pageSize,
      draft: null,
      status: "5",
      providerName: checkEmptyData(providerName),
      steelType: checkEmptyData(steelType),
      rollNsmvId: checkEmptyData(coilId),
      steelGrade: checkEmptyData(steelGrade),
      importDate: !!checkEmptyData(importDate) ? dayjs(importDate).format('DD/MM/YYYY') : null,
      netWeight: checkEmptyData(netWeight),
      sizeWidth: checkEmptyData(sizeRangeValidate.sizeWidth),
      sizeDepth: checkEmptyData(sizeRangeValidate.sizeDepth),
      sizeWidthTo: checkEmptyData(sizeRangeValidate.sizeWidthTo),
      sizeDepthTo: checkEmptyData(sizeRangeValidate.sizeDepthTo),
      saveInStock: 1,
      sortRequest: {
        importDate : "desc",
        nsmvCoilId : "asc",
        steelGrade : null,
        steelType : null,
        sizeDepth : null,
        sizeWidth : null,
        netWeight : null,
        temWeight: null,
        providerName : null,
        producerCode : null,
        note : null
      }
      
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const requestGetData = (dataRequest) => {
    set_loading(true)
    AxiosService.post(apis.Url_Api_Post_LayDanhSachTonKho, dataRequest)
      .then(response => {
        let { data } = response;
        console.log(response);
        if (!!data && data.message === "OK") {
          if (dataRequest.start === 0) {
            set_total(data.totalRecords)
            set_totalWeight(data.totalWeight)
          }
          set_data(data.data)
        } else if (data.message === keys.api_message_EMPTY) {
          set_total(0)
          set_data([])
          set_totalWeight(0)
          notification.error({message: "Không có dữ liệu"})
        }
        set_loading(false)
      })
      .catch(err => {console.log(err);
        notification.error({message: "Lỗi hệ thống"})
        set_loading(false)
        })
  }

  const onChangePageSize = (val) => {
    set_pageSize(val);
    set_currentPage(1);
    let dataRequest = {
      ...currentDataRequest,
      start: 0,
      length: val,
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const onChangePage = (val) => {
    set_currentPage(val)
    let dataRequest = {
      ...currentDataRequest,
      start: pageSize * (val - 1),
      length: pageSize * val,
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const onClickRow = (record, index) => {
    if (selectedRecord.some(item => item.id === record.id)) {
      set_selectedRecord(currentData => currentData.filter(item => item.id !== record.id))
    } else {
      set_selectedRecord(currentData => [...currentData, record])
    }
  }

  const selectAllCurrentList = ({ target }) => {
    if (target.checked) {
      set_selectedRecord(currentData => [...currentData, ...data.filter((record) => !currentData.map(item => item.id).includes(record.id))])
    } else {
      set_selectedRecord(currentData => currentData.filter(record => !data.map(item => item.id).includes(record.id)))
    }
  }
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isPressCreateTicket, setIsPressCreateTicket] = useState(false);

  const onPressCreateTicketApprove = (isFinish = false ) => {
    if(selectedRecord.length === 0) {
      notification.error({message: "Chưa có cuộn nào được chọn"})
      return;
    }
    setIsPressCreateTicket(isFinish)
    setIsApprove(true);
    setIsOpenModal(true)
  }

  const onPressCreateTicket = (isFinish = false ) => {
    if(selectedRecord.length === 0) {
      notification.error({message: "Chưa có cuộn nào được chọn"})
      return;
    }
    setIsPressCreateTicket(isFinish)
    setIsOpenModal(true)
  }


  const onClickSave = (misaNameAcronym = "SR", deliverDate, isFinish = false) => {
    dispatch(appLoading(true))
    let dataRequest = {
      "listIdRollTable": selectedRecord.map(record => record.id),
      isRecentFinish: isFinish,
      misaNameAcronym,
      deliverDate
      
    }
    AxiosService.post(apis.Url_Api_Post_TaoChiThiXuatKho, dataRequest)
      .then(response => {
        let { data } = response;
        if (!!data && data.message === "OK") {
          notification.success({message: "Tạo chỉ thị thành công"})
          history.push(paths.main_danh_sach_chi_thi_xuat_kho)
          requestGetNotiToken()
        }
        dispatch(appLoading(false))
      })
      .catch(err =>{ 
        console.log("Lỗi xuất",err);
        dispatch(appLoading(false));
        notification.error({message: "Lỗi hệ thống"});
      })
  }

  const requestGetNotiToken = () => {
    let dataRequest = {
      "lstRole" : [keys.userRoles.KhoTonCuon_PheDuyet]
    }
    AxiosService.post(apis.Url_Get_ListMessageTokenByRole, dataRequest)
      .then(response => {
        let { data } = response;
        if (!!data && data.message === "OK" && data.status === 200) {
          requestSendNoti(data.data[keys.userRoles.KhoTonCuon_PheDuyet])
        }else {
        }
      })
      .catch(err => {
        
        console.log(err)})
  }

  const requestSendNoti = (listToken = []) => {
    let dataRequest = {
      "subject" : "NSMP STORAGE",
      "content" : "Có yêu cầu xuất kho mới cần phê duyệt",
      "data" : {
         "message" : "xuất kho mới"
      },
      "registrationToken" : listToken.map(item => item.token)
  }
    AxiosService.post(apis.Url_Get_SendNotification, dataRequest)
      .then(response => {
        let { data } = response;
      })
      .catch(err => console.log(err))
  }

  const columns = [
    {
      title: <Checkbox onClick={selectAllCurrentList} checked={!data.some(record => !selectedRecord.map(item => item.id).includes(record.id)) && selectedRecord.length > 0} />,
      dataIndex: 'checked',
      key: 'checked',
      width: '35px',
      fixed: 'left',
      render: (text, record) => <Checkbox checked={selectedRecord.some(item => item.id === record.id)} />,
    },
    {
      title: 'Ngày nhập',
      titleConfig: 'Ngày nhập',
      dataIndex: 'importDate',
      key: 'importDate',
      width: '115px',
      fixed: 'left',
      // render: (value) => moment(value).format('DD/MM/YYYY'),
    },
    {
      title: 'Số cuộn',
      dataIndex: 'nsmvCoilId',
      titleConfig: 'Số cuộn',
      key: 'nsmvCoilId',
      fixed: 'left',
      width: '150px',
      render: (value) => <div className={style["important-text"]}>{value}</div>
    },
    {
      title: 'Mác Thép',
      titleConfig: 'Mác Thép',
      dataIndex: 'steelGrade',
      width: '115px',
      key: 'steelGrade',
    },
    {
      title: 'Chủng loại',
      titleConfig: 'Chủng loại',
      width: '115px',
      dataIndex: 'steelType',
      key: 'steelType',
    },
    {
      title: <div><div>Dày x rộng</div><div style={{paddingLeft: '18px'}}>(mm)</div></div>,
      titleConfig: 'Dày x rộng',
      dataIndex: 'size',
      key: 'size',
      width: '150px',
      render: (size, item) => (item.sizeDepth.toString().includes(".") ? item.sizeDepth : item.sizeDepth + ".0") + " × " + (item.sizeWidth.toString().includes(".") ? item.sizeWidth : item.sizeWidth + ".0")
    },
    {
      title: <div><div>Trọng lượng</div><div style={{paddingLeft: '25px'}}>(Kg)</div></div>,
      titleConfig: 'Trọng lượng còn lại',
      dataIndex: 'netWeight',
      key: 'netWeight',
      width: '120px',
      render: (value) => formatWeightText(value)
    },
    {
      title: 'Nhà cung cấp',
      titleConfig: 'Nhà cung cấp',
      dataIndex: 'providerName',
      width: '150px',
      key: 'providerName',
    },
      
    {
      title: 'Ghi chú',
      titleConfig: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      width: '150px',
    },
    {
      title: 'Ngày thí nghiệm',
      titleConfig: 'Ngày thí nghiệm',
      width: '115px',
      dataIndex: 'pullDate',
      render: (value, {pullingResponse}) => !!pullingResponse ? moment(pullingResponse.importDate).format('DD/MM/YYYY')  : '',
      key: 'pullDate'
      
    },{
      title: 'Loại/Kích thước kéo',
      titleConfig: 'Loại/Kích thước kéo',
      width: '150px',
      dataIndex: 'typeSize',
      render: (value, {pullingResponse}) => !!pullingResponse ? pullingResponse.typeSize  : '',
      key: 'typeSize'
    },{
      title: 'Phương pháp kéo',
      titleConfig: 'Phương pháp kéo',
      width: '150px',
      dataIndex: 'methodUsed',
      render: (value, {pullingResponse}) => !!pullingResponse ? pullingResponse.methodUsed  : '',
      key: 'methodUsed'
    },{
      title: 'Giới hạn chảy',
      titleConfig: 'Giới hạn chảy',
      width: '150px',
      dataIndex: 'yieldStrength',
      render: (value, {pullingResponse}) => !!pullingResponse ? pullingResponse.yieldStrength  : '',
      key: 'yieldStrength'
    },{
      title: 'Độ giãn dài',
      titleConfig: 'Độ giãn dài',
      width: '150px',
      dataIndex: 'elongation',
      render: (value, {pullingResponse}) => !!pullingResponse ? pullingResponse.elongation  : '',
      key: 'elongation'
    },{
      title: 'Giới hạn bền',
      titleConfig: 'Giới hạn bền',
      width: '150px',
      dataIndex: 'tensileStrength',
      render: (value, {pullingResponse}) => !!pullingResponse && !!pullingResponse.firstTensileStrength ? pullingResponse.firstTensileStrength + " × "  + pullingResponse.secondTensileStrength + " × "  + pullingResponse.thirdTensileStrength  : '',
      key: 'tensileStrength'
    },{
      title: 'Kết quả kéo',
      titleConfig: 'Kết quả kéo',
      width: '150px',
      dataIndex: 'result',
      render: (value, {pullingResponse}) => !!pullingResponse ? getPullingSampleResult(pullingResponse.result)  : 'Chưa thí nghiệm',
      key: 'result'
    },{
      title: 'Ghi chú thí nghiệm',
      titleConfig: 'Ghi chú thí nghiệm',
      width: '150px',
      dataIndex: 'pullNote',
      render: (value, {pullingResponse}) => !!pullingResponse ? pullingResponse.pullNote  : '',
      key: 'pullNote'
    },

  ];
  const handleOpenChange = (boolean) => {
    set_openConfig(boolean);
  };

  return (
    <div className={style["main-container"]}>
      <Breadcrumb items={[{
        title: 'Xuất kho',
      },
      {
        title: 'Tạo mới',
      }]} />

      <div className={style['main']}>
        <Row gutter={[10, 20]} align={'middle'}>
          <Col span={3} className={style['filter-title']}>Nhà cung cấp</Col>
          <Col span={5}><Input className={style['filter-input']} value={providerName} onChange={({ target }) => set_providerName(target.value)} /></Col>

          <Col span={3} className={style['filter-title']}>Số cuộn</Col>
          <Col span={5}><Input className={style['filter-input']} value={coilId} onChange={({ target }) => set_coilId(target.value)} /></Col>

          <Col span={3} className={style['filter-title']}>Mác thép</Col>
          <Col span={5}><Input className={style['filter-input']} value={steelGrade} onChange={({ target }) => set_steelGrade(target.value)} /></Col>

          <Col span={3} className={style['filter-title']}>Chủng loại</Col>
          <Col span={5}><Select
            className={style['filter-select']}
            onChange={set_steelType}
            defaultValue={''}
            value={steelType}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={app.steelTypeMaster}
          /></Col>

          <Col span={3} className={style['filter-title']}>Dày × Rộng</Col>
          <Col span={5}> <Flex align="center">
              <Input className={style['filter-input']} value={sizeDepth} onChange={({ target }) => set_sizeDepth(target.value.replace(/[^0-9.]/g, ""))} />
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
              <Input className={style['filter-input']} value={sizeWidth} onChange={({ target }) => set_sizeWidth(target.value.replace(/[^0-9.]/g, ""))} />
              </Flex>

              <Flex align="center" justify='space-around'>
              <ArrowDownOutlined />
              <ArrowDownOutlined />
              </Flex>

              <Flex align="center">
              <Input className={style['filter-input']} value={sizeDepthTo} onChange={({ target }) => set_sizeDepthTo(target.value.replace(/[^0-9.]/g, ""))} />
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
              <Input className={style['filter-input']} value={sizeWidthTo} onChange={({ target }) => set_sizeWidthTo(target.value.replace(/[^0-9.]/g, ""))} />
              </Flex>
          </Col>

          <Col span={3} className={style['filter-title']}>Trọng lượng</Col>
          <Col span={5}><Input className={style['filter-input']} value={netWeight} onChange={({ target }) => set_netWeight(target.value.replace(/[^0-9]/g, ""))} /></Col>



          <Col span={3} className={style['filter-title']}>Ngày Nhập</Col>
          <Col span={5}><DatePicker format={"DD/MM/YYYY"}  className={style['filter-input']} value={importDate} onChange={(date, dateString) => {set_importDate(date)}  } /></Col>

        </Row>

        <Row gutter={30} align={'middle'} justify={'center'} style={{ marginTop: '20px' }}>
          <Col><Button onClick={onPressFilter} icon={<SearchOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tìm kiếm</Button></Col>
          <Col><Button onClick={onPressClearFilter} icon={<IconEraser style={{ height: '16px', width: '16px' }} />} className={style['filter-button']}>Xóa</Button></Col>

        </Row>

        <Row gutter={30} align={'middle'} justify={'space-between'} style={{ marginTop: '20px' }}>
          <Col>Đã chọn: {selectedRecord.length}</Col>
          <Col flex={'auto'}>Tổng trọng lượng: {formatWeightText(selectedRecord.reduce((prev, curr) => prev + curr.netWeight, 0))} Kg</Col>
          <Col><Popover
              placement="bottomRight"
              content={<div>
                <Row style={{ width: '800px' }}>
                  {columns.filter(item => !!item.titleConfig).map(item => 
                  <Col span={6}>
                    <Checkbox 
                    disabled = {!!item.fixed}
                    onChange={({target}) => set_tableConfig(currentState => ({...currentState, [`${item.key}`] : target.checked}))}
                    checked={tableConfig[`${item.key}`]}>{item.titleConfig}</Checkbox>
                    </Col>
                    )}
                </Row>
              </div>}
              trigger="click"
              open={openConfig}
              onOpenChange={handleOpenChange}
            ><SettingOutlined style={{marginRight: '10px', marginLeft: '10px', fontSize : '20px', color:'#2c3782'}} />
            </Popover></Col>
          {currentRole.includes(keys.userRoles.KhoTonCuon_PheDuyet) &&<Col style={{display: 'flex', alignItems: 'center'}}>
          <Button onClick={() => onPressCreateTicketApprove(true)} className={style['filter-button']} style={{ backgroundColor: '#7ED9FF', color: '#000' }}>Tạo chỉ thị không cần duyệt</Button></Col>}
          {currentRole.includes(keys.userRoles.KhoTonCuon) && 
            <Col >
              <Button
                onClick={() => onPressCreateTicket()}
                className={style['filter-button']}
                style={{ backgroundColor: '#7ED9FF', color: '#000' }}
              >
                Tạo chỉ thị
              </Button>
            </Col>
          }
        </Row>

        <Table loading = {loading} columns={columns.filter(item => {
          if (!!tableConfig[`${item.key}`] || !!item.fixed) {
            return true
          }
          return false
        })}  dataSource={data} className={style['table']} pagination={{ position: ['none', 'none'], pageSize: pageSize }} rowClassName={style['table-row']} onRow={(record, rowIndex) => {
          return {
            onClick: (event) => { onClickRow(record, rowIndex) }, // click row
          };
        }} rowKey={'id'}  scroll={{ x: '100%' }}/>
        <Row justify={'end'} className={style['pagination']}>
          <Pagination
            locale={{ items_per_page: '' }}
            pageSizeOptions={[5, 10, 20]}
            className={style["pagination-bar"]}
            pageSize={pageSize}
            hideOnSinglePage
            current={currentPage}
            onChange={(index) => onChangePage(index)}
            defaultCurrent={1}
            total={total}
            showSizeChanger = {false}
          />
          <Select value={pageSize} defaultValue={pageSize} options={configs.PageSizes} onChange={onChangePageSize} style={{ marginLeft: '10px' }} pagination={{ position: ['none', 'none'], pageSize: pageSize }} />
        </Row>

      </div>
      <TaoChiThiXuatKhoModal 
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        setIsApprove={setIsApprove}
        onClickSave={onClickSave}
        isPressCreateTicket={isPressCreateTicket}
        ref={null} reloadData={() => {}}
        isApprove={isApprove}
      />
    </div>
  );


};



export default XuatKho;


const configSample = {
  importDate: true,
  nsmvCoilId: true,
  steelGrade: true,
  steelType: false,
  size: true,
  temWeight: true,
  netWeight: true,
  providerName: true,
  producerCode: true,
  note: true,
  checked: true
}